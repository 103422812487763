import React from "react";
import { Container, Text, Button } from "@atoms";
import PeopleTopPattern from "@svg/PeopleTopPattern";

const NumberedList = ({ items, button }) => {
  return (
    <div className="my-10 sm:my-20">
      <Container className="relative">
        <div className="flex flex-col items-center gap-8 pb-16 sm:gap-12 sm:pb-24">
          <div className="relative z-10 flex flex-col items-center gap-4">
            {items?.map((item, i) => {
              const { label, descriptor } = item;
              return (
                <div className="w-full max-w-2xl rounded-br-3xl rounded-tl-3xl bg-white p-4 shadow-lg">
                  <div className="flex w-full flex-wrap items-center gap-4 sm:flex-nowrap sm:gap-12">
                    <div className="flex shrink-0 flex-col items-center gap-2 rounded-br-3xl rounded-tl-3xl bg-slate px-6 py-4 sm:px-8">
                      <span className="text-sm text-white">{label}</span>
                      <span className="text-4xl font-bold text-teal sm:text-6xl">
                        {i + 1}
                      </span>
                    </div>
                    <Text variant="body">{descriptor}</Text>
                  </div>
                </div>
              );
            })}
          </div>
          {button?.url && (
            <Button to={button.url} color="purple" size="sm">
              {button.text || "Learn More"}
            </Button>
          )}
        </div>
        <div className="absolute inset-0 top-20 overflow-hidden rounded-4xl bg-purple/10">
          <PeopleTopPattern className="absolute -bottom-16 -right-4 w-[27.5rem] rotate-180 text-white" />
        </div>
      </Container>
    </div>
  );
};

export default NumberedList;
