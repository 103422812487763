import React from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import getAnimation from "@utils/getAnimation";

const PeopleTopPattern = ({ fitHeight, className: _className }) => {
  const draw = getAnimation("draw");
  return (
    <span
      className={clsx("icon block", _className, {
        "icon--fit-height": fitHeight,
      })}
    >
      <m.svg
        xmlns="http://www.w3.org/2000/svg"
        width="588"
        height="493"
        viewBox="0 0 588 493"
        initial="hidden"
        whileInView="visible"
        className="overflow-visible stroke-2 opacity-40 sm:stroke-1 sm:opacity-100"
        viewport={{
          once: true,
        }}
      >
        <m.path
          d="M325.297 140.546H488.324C494.759 140.546 499.994 145.726 500.062 152.161C500.539 197.28 464.095 234.112 418.973 234.112H338.798C325.645 234.112 315.021 223.376 315.16 210.223L315.798 149.945C315.853 144.738 320.09 140.546 325.297 140.546Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M215.436 183.383V257.165C215.436 262.412 211.183 266.665 205.936 266.665H133.907C127.259 266.665 121.87 261.276 121.87 254.628V253.28C121.87 208.141 158.462 171.549 203.601 171.549C210.137 171.549 215.436 176.847 215.436 183.383Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M353.659 180.891V224.154C353.659 272.462 314.498 311.623 266.19 311.623C262.823 311.623 260.093 308.893 260.093 305.525V181.049C260.093 175.802 264.346 171.549 269.593 171.549H344.316C349.476 171.549 353.659 175.731 353.659 180.891Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M181.491 109.538L131.827 109.538C83.5191 109.538 44.358 148.699 44.358 197.006C44.358 200.374 47.0881 203.104 50.4557 203.104L174.932 203.104C180.179 203.104 184.432 198.851 184.432 193.604L184.432 112.479C184.432 110.855 183.115 109.538 181.491 109.538Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M8.14822 492.072H6.70609C3.27872 492.072 0.500298 489.294 0.500298 485.866L0.500298 408.006C0.500298 402.759 4.75359 398.506 10.0003 398.506L89.5191 398.506C92.8867 398.506 95.6167 401.236 95.6167 404.604C95.6167 452.911 56.4557 492.072 8.14822 492.072Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M59.1218 360.072H57.6797C54.2524 360.072 51.4739 357.294 51.4739 353.866V276.006C51.4739 270.759 55.7272 266.506 60.9739 266.506H140.493C143.86 266.506 146.59 269.236 146.59 272.604C146.59 320.911 107.429 360.072 59.1218 360.072Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M533.032 109.538H531.59C528.163 109.538 525.384 112.317 525.384 115.744V193.604C525.384 198.851 529.637 203.104 534.884 203.104H614.403C617.77 203.104 620.5 200.374 620.5 197.007C620.5 148.699 581.34 109.538 533.032 109.538Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M298.954 172.1H224.387C219.141 172.1 214.887 167.847 214.887 162.6V88.0338C214.887 82.7871 219.141 78.5338 224.387 78.5338H298.954C304.2 78.5338 308.454 82.7871 308.454 88.0338V162.6C308.454 167.847 304.2 172.1 298.954 172.1Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M423.801 131.232V87.9685C423.801 39.661 462.962 0.5 511.27 0.5C514.638 0.5 517.368 3.23001 517.368 6.59764V131.074C517.368 136.321 513.114 140.574 507.868 140.574H433.144C427.984 140.574 423.801 136.391 423.801 131.232Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M96.066 182.6L96.066 390.342C96.066 394.813 92.4409 398.439 87.9691 398.439C40.7658 398.439 2.49991 360.173 2.49991 312.969L2.49991 182.6C2.49991 177.353 6.7532 173.1 11.9999 173.1H86.566C91.8127 173.1 96.066 177.353 96.066 182.6Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
      </m.svg>
    </span>
  );
};

PeopleTopPattern.defaultProps = {};

export default PeopleTopPattern;
